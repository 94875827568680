<template>
    <div class="wrapper systemMsg">
        <header>
            <div>
                <el-form :inline="true" :model="formInline">
                    <el-form-item label="时间段">
                        <el-select placeholder="全部" v-model="formInline.DateRange" @change="changeDateRange">
                            <el-option label="全部" :value="0"></el-option>
                            <el-option label="本周" :value="1"></el-option>
                            <el-option label="上周" :value="2"></el-option>
                            <el-option label="本月" :value="3"></el-option>
                            <el-option label="上月" :value="4"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="搜索">
                        <el-input
                            prefix-icon="el-icon-search"
                            placeholder="请输入搜索内容"
                            v-model="formInline.Keyword"
                            @keyup.enter.native="searchEnterFun"
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            @click="searchFun"
                            class="el-icon-search"
                            type="primary"
                            size="mini"
                        >
                            查询
                        </el-button>
                    </el-form-item>
                </el-form>
                <div class="fr">
                    <span class="addSystemMsg fl" @click="showDialogueFn">
                        <span class="iconfont iconzhifeiji"></span>
                        发布系统消息
                    </span>
                    <span class="addSystemMsg fl" @click="readAll">
                        全部标为已读
                    </span>
                    <!-- <div class="demo-form-inline fl">
                        <el-select
                            placeholder="全部"
                            v-model="formInline.DateRange"
                            @change="changeDateRange"
                            class="fl"
                        >
                            <el-option label="全部" :value="0"></el-option>
                            <el-option label="本周" :value="1"></el-option>
                            <el-option label="上周" :value="2"></el-option>
                            <el-option label="本月" :value="3"></el-option>
                            <el-option label="上月" :value="4"></el-option>
                        </el-select>
                        <el-input
                            class="fl search-input"
                            placeholder="回车搜索..."
                            v-model="formInline.Keyword"
                            @keyup.enter.native="searchEnterFun"
                        ></el-input>
                    </div> -->
                    <!-- <el-form :inline="true" :model="formInline" class="demo-form-inline fl">
                        <el-form-item>
                            <el-select placeholder="全部" v-model="formInline.DateRange" @change="changeDateRange">
                                <el-option label="全部" :value="0"></el-option>
                                <el-option label="本周" :value="1"></el-option>
                                <el-option label="上周" :value="2"></el-option>
                                <el-option label="本月" :value="3"></el-option>
                                <el-option label="上月" :value="4"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-input placeholder="回车搜索..." v-model="formInline.Keyword" @keyup.enter.native="searchEnterFun"></el-input>
                        </el-form-item>
                    </el-form> -->
                </div>
            </div>
        </header>
        <div class="content_box">
            <div>
                <el-radio-group
                    v-model="IsSign"
                    @change="handleTabClick"
                    style="margin-bottom: 30px;"
                    class="fl"
                >
                    <el-radio-button :label="0">
                        全部
                    </el-radio-button>
                    <el-radio-button :label="1">
                        待签收
                    </el-radio-button>
                    <el-radio-button :label="2">
                        已签收
                    </el-radio-button>
                </el-radio-group>
            </div>
            <div class="table_box">
                <iTable
                    :table-data="dataList"
                    :columns="columns"
                    :pagination="pagination"
                    tabletype="3"
                    ref="iTable"
                    :other-height="230"
                    :border="false"
                    @handleTableChange="handleTableChange"
                    @readAllClick="readAll"
                    @msgRead="msgRead"
                    @starFn="starFn"
                ></iTable>
            </div>
        </div>
        <iDialog :title="dialogTitle" :visible="dialogVisible" @close="closeFn">
            <div slot="body" style="height:100%">
                <addSysMsg></addSysMsg>
            </div>
        </iDialog>
        <!-- <iDialog
            :title="signD.dialogTitle"
            :visible="signD.dialogVisible"
            :options="signD.id"
            @close="signCloseFn"
        >
            <div slot="body" style="height:100%">
                <signSysMsg></signSysMsg>
            </div>
        </iDialog> -->
    </div>
</template>

<script>
import iTable from './components/iTable';
import addSysMsg from './components/addSysMsg';
import { eventBus } from '@/common/eventBus.ts';
import util from '@/utils/util';
export default {
    name: 'system-msg',
    components: { iTable, addSysMsg },
    props: {},
    data() {
        return {
            currentIndex: 1,
            pagination: { size: 10 },
            dataList: [],
            columns: [
                {
                    prop: 'is_read',
                    label: '=',
                    width: 100,
                    format: row => {
                        let str = '';
                        if (row.is_read) {
                            str = '<span class="iconfont icondot readed"></span>';
                        } else {
                            str = '<span class="iconfont icondot unread"></span>';
                        }
                        return str;
                    },
                    isHeader: true,
                },
                {
                    prop: 'title',
                    label: '标题',
                    format: row => {
                        return '<a href="javascript:;">' + row.title + '</a>';
                    },
                    method: this.openDetail,
                },
                {
                    prop: 'content',
                    label: '消息内容',
                    width: 500,
                },
                {
                    prop: 'dispatcher',
                    label: '消息发送人',
                },
                {
                    prop: 'is_star',
                    label: '是否标记',
                    format: row => {
                        let str = '';
                        if (row.is_star) {
                            str = '<span class="iconfont iconicon-starfilled"></span>';
                        } else {
                            str = '<span class="iconfont iconicon-star"></span>';
                        }
                        return str;
                    },
                    method: this.starFn,
                },

            ],
            operateColumn: {
                width: 110,
                fixed: 'right',
                list: [],
            }, // 操作列
            loading: false,
            IsSign: 0, // 签收状态 0：全部 1：未签收 2：已签收
            formInline: {
                DateRange: 0,
                Keyword: '',
            },
            dialogTitle: '发布系统消息',
            dialogVisible: false,
            signD: {
                dialogTitle: '查看签收系统消息',
                dialogVisible: false,
                id: {
                    id: '',
                },
            },

        };
    },
    watch: {},
    computed: {},
    created() {
        this.getMsgList();
    },
    methods: {
        searchFun() {
            this.getMsgList();
        },
        openDetail(row) {
            this.msgRead(row.id);
            this.$toast({
                title: true,
                text: '签收系统消息',
                type: 'eject',
                t_url: 'messageCenter/pages/page/components/signSysMsg',
                viewPosition: 'view',
                extr: { id: row.id, closeBtn: {} },
            });
        },
        handleTableChange() {
            if (Math.ceil(this.pagination.total / this.pagination.size) !== this.currentIndex) {
                this.currentIndex++;
                this.getMsgList();
            }
        },
        showDialogueFn() {
            const IsManager = util.jwtToken('IsManager');
            if (IsManager === '1') {
                this.dialogVisible = true;
            } else {
                this.$message.warning('只有租户的系统管理员才有此功能!');
            }
        },
        // 拉取消息列表（分页）
        getMsgList(i) {
            const index = i ? Math.floor(i / 10) : this.currentIndex;
            const qs = require('qs');
            const param = {
                type: 1,
                Keyword: this.formInline.Keyword,
                DateRange: this.formInline.DateRange,
                IsSign: this.IsSign,
                pageindex: index,
                pagesize: 10,
            };
            this.$axios
                .get('/interfaceApi/message/MessageInfo/get_page?' + qs.stringify(param))
                .then(res => {
                    if (i || i === 0) {
                        const data = [];
                        res.rows.forEach(item => {
                            this.dataList.forEach(dat => {
                                if (item.Id === dat.Id) {
                                    data.push(item);
                                } else {
                                    data.push(dat);
                                }
                            });
                        });
                        this.dataList = data;
                    } else {
                        this.dataList.push(...res.rows);
                    }
                    this.pagination.total = res.total;
                }).catch(error => {
                    this.$message.error(error.message);
                });
        },
        // 签收状态筛选
        handleTabClick(IsSign) {
            this.IsSign = IsSign;
            this.getMsgList();
        },
        // 日期范围筛选
        changeDateRange() {
            this.getMsgList();
        },
        // 模糊搜索
        searchEnterFun(e) {
            const keyCode = window.event ? e.keyCode : e.which;
            if (keyCode === 13) {
                this.getMsgList();
            }
        },
        closeFn() {
            this.dialogVisible = false;
            this.getMsgList();
        },
        signCloseFn() {
            this.signD.dialogVisible = false;
            this.getMsgList();
        },
        // 标记操作
        starFn(row, i) {
            if (row.IsStar) {
                this.removeStar(row.Id, i);
            } else {
                this.addStar(row.Id, i);
            }

        },
        // 添加标记
        addStar(id, i) {
            this.$axios
                .put('/interfaceApi/message/MessageInfo/add_star/' + id)
                .then(res => {
                    this.$message.success(res);
                    this.getMsgList(i);
                }).catch(error => {
                    this.$message.error(error.message);
                });
        },
        // 删除标记
        removeStar(id, i) {
            this.$axios
                .put('/interfaceApi/message/MessageInfo/remove_star/' + id)
                .then(res => {
                    this.$message.success(res);
                    this.getMsgList(i);
                }).catch(error => {
                    this.$message.error(error.message);
                });
        },
        // 已读消息
        msgRead(id) {
            this.$axios
                .put('/interfaceApi/message/MessageInfo/message_read/' + id)
                .then(() => {
                    // this.$message.success(res);
                    this.getMsgList();
                    eventBus.$emit('updateSysUnread');
                }).catch(error => {
                    this.$message.error(error.message);
                });
        },
        // 一键已读
        readAll() {
            this.$axios
                .get('/interfaceApi/message/MessageInfo/batch_read?type=1')
                .then(() => {
                    this.$message.success('操作成功');
                    this.getMsgList();
                    eventBus.$emit('updateSysUnread');
                }).catch(error => {
                    this.$message.error(error.message);
                });
        },
    },
    mounted() {
        // 添加事件总线eventBus
        eventBus.$on('updateSysMsg', () => {
            this.getMsgList();
        });
    },
};
</script>
<style lang="stylus">
.systemMsg
    padding .1rem
    height 100%
    background: #F8F9FA;
    .iconfont
        font-size .16rem
    .search-input
        width 1.5rem
        margin-left .1rem
    .addSystemMsg
        line-height .40rem
        vertical-align top
        font-size .16rem
        color #47a2ff
        margin-right .10rem
        cursor pointer
        border: 1px solid #47a2ff;
        padding: 0 .1rem;
        border-radius 3px
    .el-form
        display inline-block
    a
        color #47a2fd
    .el-radio-button__inner
        padding .12rem .20rem
        font-size .14rem
    .el-radio-group
        margin-bottom .3rem!important
    .content_box
        display flex
        flex-direction: column;
        height calc(100% - 1.5rem)
        .table_box
            height 100%
</style>